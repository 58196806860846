import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { colors, radius, COLOR_CONSTANTS, fontSizes, fontWeights, space } from 'theme'
import {
  CONTAINER_MAX_WIDTH,
  ANALYTICS,
  ENGAGEMENT,
  LISTENING,
  PUBLISHING,
  REVIEWS,
  SITE_LINK_PUBLISHING,
  SITE_LINK_ANALYTICS,
  SITE_LINK_LISTENING,
  SITE_LINK_REVIEWS,
  SITE_LINK_ENGAGEMENT,
} from 'consts'
import { pxToRem } from 'helpers'
import { Flex, Grid } from 'components/Layout'
import { Text } from 'components/Typography'
import Button from 'components/Button'
import LocalImage from 'components/LocalImage'

import imageAnalytics from 'static/images/get_started/analytics.svg'
import imageEngagement from 'static/images/get_started/engagement.svg'
import imageListening from 'static/images/get_started/listening.svg'
import imagePublishing from 'static/images/get_started/publishing.svg'
import imageReviews from 'static/images/get_started/reviews.svg'
import imageDoubleArrowRightWhite from 'static/images/home/double_arrow_right_white.svg'

const StyledWrapper = styled(Flex)`
  background: radial-gradient(51.88% 56.86% at 31.54% 45.31%, ${colors.primary} 36.77%, #0250c9 100%);
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const StyledGetStartedButton = styled(Button.Reversed)`
  font-size: ${fontSizes.m};
  height: 100%;
  padding-left: ${pxToRem(48)};
  padding-right: ${pxToRem(48)};
`

const StyledHeaderTextWrapper = styled(Flex)`
  border-radius: ${radius.m};
  background-color: ${transparentize(0.8, colors.white)};
`

const StyledItemWrapper = styled(Flex)`
  background: ${colors.primary};
  border: 1px solid #2e89ff;
  border-radius: ${radius.xl};
  justify-content: space-between;
  align-items: center;
  padding: ${space.m};
  cursor: pointer;
  &:hover {
    background: ${COLOR_CONSTANTS.SKY};
  }
`

const TABS = [PUBLISHING, ANALYTICS, ENGAGEMENT, LISTENING, REVIEWS]

const ITEMS = [
  { name: 'Publishing', key: PUBLISHING, icon: imagePublishing, link: SITE_LINK_PUBLISHING },
  { name: 'Analytics', key: ANALYTICS, icon: imageAnalytics, link: SITE_LINK_ANALYTICS },
  { name: 'Engagement', key: ENGAGEMENT, icon: imageEngagement, link: SITE_LINK_ENGAGEMENT },
  { name: 'Listening', key: LISTENING, icon: imageListening, link: SITE_LINK_LISTENING },
  { name: 'Reviews', key: REVIEWS, icon: imageReviews, link: SITE_LINK_REVIEWS },
]

const GetStartedComponent = ({ activeKey }) => {
  let updatedActiveKey = null
  let foundActiveTab = null
  let SORTED_ITEMS = ITEMS

  if (activeKey) {
    updatedActiveKey = TABS.includes(activeKey) ? activeKey : PUBLISHING
    foundActiveTab = ITEMS.find(({ key }) => key === updatedActiveKey)
    SORTED_ITEMS = [...ITEMS.filter(({ key }) => key !== updatedActiveKey), foundActiveTab]
  }

  return (
    <StyledWrapper>
      <Flex
        py={{ mobile: 'l', tablet: 'xl' }}
        pl={{ mobile: 'l', tablet: 'l' }}
        pr={{ mobile: 'l', tablet: 'l' }}
        mx="auto"
        maxWidth={CONTAINER_MAX_WIDTH}
        width="100%"
        height="100%"
        justifyContent="space-between"
        flexDirection={{ mobile: 'column', tablet: 'row' }}
        alignItems={{ mobile: 'center', tablet: 'unset' }}
      >
        <Flex flexDirection="column" maxWidth="500px" alignItems={{ mobile: 'center', tablet: 'unset' }}>
          <Flex>
            <StyledHeaderTextWrapper fontWeight="bold" fontSize="3xl" color="white">
              Explore the full
            </StyledHeaderTextWrapper>
          </Flex>
          <Text fontWeight="bold" fontSize="3xl" color="white" textAlign="left">
            Vista Social platform
          </Text>
          <Text mt="m" pt="s" textAlign={{ mobile: 'center', tablet: 'left' }} color={COLOR_CONSTANTS.SALT}>
            One easy-to-use solution, loaded with powerful features. Everything you need to dominate social media with
            fanatical support from our team of experts!
          </Text>
          <Flex mt="xl" height={{ mobile: pxToRem(56), tablet: pxToRem(52) }}>
            <StyledGetStartedButton as="a" href="/pricing">
              Get started now
            </StyledGetStartedButton>
          </Flex>
        </Flex>
        <Flex mt={{ mobile: 'l', tablet: '0' }} flexDirection="column">
          {foundActiveTab && (
            <Text
              mb="m"
              fontWeight="medium"
              fontSize="s"
              color={COLOR_CONSTANTS.SALT}
              textAlign={{ mobile: 'center', tablet: 'left' }}
            >
              Vista Social is not only about {foundActiveTab.name}
            </Text>
          )}
          <Grid gridGap="s" gridTemplateColumns="repeat(1, 1fr)">
            {SORTED_ITEMS.map(({ key, name, icon, link }) => {
              const isActive = key === updatedActiveKey
              return (
                <StyledItemWrapper key={key} width="350px" as="a" href={link} opacity={isActive ? 0.5 : 1}>
                  <Flex alignItems="center">
                    <Flex width="24px" height="24px" mx="s">
                      <LocalImage src={icon} width="100%" height="100%" />
                    </Flex>
                    <Text ml="xs" fontSize="l" fontWeight="bold" color="white">
                      {name}
                    </Text>
                  </Flex>
                  <LocalImage src={imageDoubleArrowRightWhite} />
                </StyledItemWrapper>
              )
            })}
          </Grid>
        </Flex>
      </Flex>
    </StyledWrapper>
  )
}

GetStartedComponent.defaultProps = {
  activeKey: null,
}

GetStartedComponent.propTypes = {
  activeKey: PropTypes.string,
}

export default GetStartedComponent
