/* eslint-disable camelcase */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { radius, COLOR_CONSTANTS } from 'theme'
import { CONTAINER_MAX_WIDTH, FREQUENTLY_ASKED_QUESTIONS } from 'consts'
import App from 'App'
import SEO from 'components/SEO'
import { Box, Flex } from 'components/Layout'
import { H1 } from 'components/Typography'
import Container from 'components/Container'
import LocalImage from 'components/LocalImage'
import Input from 'components/Input'
import Footer from 'components/Footer'
import search from 'static/svg/search.svg'
import GetStartedComponent from 'components/GetStartedComponent'
import GridDropdown from './GridDropdown'

const StyledImage = styled(LocalImage)`
  cursor: pointer;
`

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const TAB = 'all'

const Faq = ({ pageContext: { title, description, pagePath, openQuestionId } }) => {
  // const Faq = ({ pageContext }) => {

  const [tab, setTab] = useState(TAB)
  const [faq, setFaq] = useState(FREQUENTLY_ASKED_QUESTIONS[tab])
  const [searchValue, setSearchValue] = useState('')

  const handleSubmitSearch = (e) => {
    e.preventDefault()
    if (searchValue) {
      setFaq(
        FREQUENTLY_ASKED_QUESTIONS[tab].filter(
          ({ title }) => title.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
        )
      )
    } else {
      setFaq([...FREQUENTLY_ASKED_QUESTIONS[tab]])
    }
  }

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value)
    if (!e.target.value) {
      setFaq([...FREQUENTLY_ASKED_QUESTIONS[tab]])
    } else {
      handleSubmitSearch(e)
    }
  }

  // const handleChangeTab = (tab) => {
  //   setTab(tab)
  //   setFaq(FREQUENTLY_ASKED_QUESTIONS[tab])
  // }

  return (
    <App>
      <SEO title={title} description={description} path="/faq/" />
      <Wrapper bg={COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex mt="xl" mb="xxl" flexDirection="column" alignItems="center" textAlign="center">
            <H1 color={COLOR_CONSTANTS.DENIM} fontSize="4xl" fontWeight="bold">
              Frequently Asked{' '}
              <H1 fontSize="4xl" fontWeight="bold" color="primary" as="span">
                Questions
              </H1>
            </H1>

            <Box my="l">
              <form onSubmit={handleSubmitSearch}>
                <Input
                  onChange={handleSearchChange}
                  width="300px"
                  placeholder="Start typing your question"
                  pl="40px"
                  iconPosition={{ left: '10px' }}
                  iconComp={<StyledImage src={search} onClick={handleSubmitSearch} />}
                  hasBoxShadow
                  borderRadius={radius.m}
                />
              </form>
            </Box>

            <Flex flexDirection="column" mt="m" py="m" px="s" width="100%">
              <GridDropdown faqs={faq} openQuestionId={openQuestionId} />
            </Flex>
          </Flex>
        </Container>
      </Wrapper>
      <GetStartedComponent />
      <Footer />
    </App>
  )
}

Faq.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    pagePath: PropTypes.string.isRequired,
    faq: PropTypes.object,
    openQuestionId: PropTypes.string,
  }),
}

export default Faq
